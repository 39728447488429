body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  min-height: 100vh;
  background-color: #f8fafc;
}

/* global styles */
.bg-dark-blue {
  background-color: #22313e !important;
}

.bg-light-gray,
.striped-rows>tr:nth-child(even) {
  background-color: #f5f5f5 !important;
}

.highlight-bg-lightblue:hover {
  background: #f5fcfd !important;
  color: #0180b2 !important;
}

.fade-in {
  visibility: hidden;
  opacity: 0;
}

.show-on-hover:hover .fade-in {
  opacity: 1;
  transition: all .25s ease-in;
  -moz-transition: all .25s ease-in;
  -webkit-transition: all .25s ease-in;
  visibility: visible;
}

/* .icon-browser {
  display: inline-block;
  background: url(../public/assets/sprites_browser.png) no-repeat;
  height: 20px;
  width: 20px;
  margin-left: 2px;
} */


.icon-browser {
  display: inline-block;
  background: url(../public/assets/sprites_browser_new.png) no-repeat;
  height: 20px;
  width: 20px;
  margin-left: 4px;
}

.icon-firefox {
  /* background-position: -32px -36px; */
  background-position: -26px -2px;
}

.icon-chrome {
  /* background-position: 1px -6px; */
  background-position: -2px -2px;
}

.icon-ie {
  /* background-position: 2px -36px; */
  background-position: -50px -2px
}

.icon-safari {
  /* background-position: -62px -7px; */
  background-position: -75px -2px
}

.bg-mettl-blue {
  background-color: #002C77 !important;
  color: white !important;

}

.bg-mettl-blue:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.customAlert-success {
  background-color: #15853d !important;
  color: white !important;
}

.customAlert-success.MuiAlert-standardSuccess .MuiAlert-icon {
  color: white;
}

.customAlert-error.MuiAlert-standardError .MuiAlert-icon {
  color: white;
}

.customAlert-error {
  background-color: #b92424 !important;
  color: white !important;
}

.authorize {
  height: 250px;
  display: flex;
  align-items: center;
  position: relative;
}

.authorize .MuiToolbar-regular {
  flex-direction: column;
}

.authorize-box {
  display: flex !important;
  justify-content: center;
}

.authorize-button {
  margin-top: 20px !important;

}

.authorize-button span {
  text-transform: capitalize !important;

}

.btnwdth {
  width: 100% !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  /* -webkit-appearance: none; */
  margin-bottom: 1px;
  opacity: 1
}