
.react-datepicker{
    margin-top: 40px;
    position: absolute !important;
    padding: 16px;
    background: #fff;
    border-radius: 4px !important;
    font-family: 'NotoSans-Regular' !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
}
.react-datepicker__day-name{
    color: #4E6287;
    font-size: 14px ;
    font-family: 'NotoSans-Regular';
}

.react-datepicker__header{
    background-color: #fff !important;
    border-bottom:none !important
}

 .react-datepicker__current-month {
    margin-top: 0;
    color: #001F52 !important;
    font-size: 16px !important;
    font-family: 'NotoSans-Regular' !important;
    font-weight: 500 !important;
}
.react-datepicker__month {
    width: 280px !important;
}

.react-datepicker__day-names {
    display: flex;
width: 280px;
justify-content: space-between;
align-items: center;
}

.react-datepicker__day-name{
    color: #4E6287 !important;
    font-size: 14px !important;
    font-family: 'NotoSans-Regular' !important;

    display: flex;
padding: 10px 8px;
justify-content: center;
align-items: center;
}

.react-datepicker__week {
    display: flex;
    align-items: flex-start;
}

.react-datepicker__day{
    font-size: 14px;
    font-family: 'NotoSans-Regular' !important;
    color: #4E6287 !important;
    display: flex;
    line-height: normal !important;
padding: 10px 8px;
justify-content: center;
align-items: center;
}

.react-datepicker__navigation {
padding: 12px 32px !important;
top:0px !important
}

.react-datepicker__navigation--previous {
    margin-top: 16px !important;
}
.react-datepicker__navigation--next {
    margin-top: 16px !important;
}

.react-datepicker__navigation-icon {
    font-size: 16px !important;

}

.react-datepicker__navigation-icon:before{
    border-color: #001F52 !important;
    border-width: 2px 2px 0 0 !important;
}

.react-datepicker__day--selected{
    border-radius: 4px !important;
    background-color: #0B41AD !important;
    color: #fff !important;
}
