/*
 * CKEditor 5 (v40.2.0) content styles.
 * Generated on Wed, 13 Dec 2023 08:57:10 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

 :root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-selector-caption-background: hsl(0, 0%, 97%);
    --ck-color-selector-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
    --ck-color-text: #4E6287 !important;
    --ck-color-tooltip-background: #002c77 !important;
    --ck-color-list-button-on-background: #fff !important;
    --ck-color-list-button-on-background-focus:#f0f0f0 !important;
    --ck-color-list-button-on-text: #4E6287 !important;
}





  .ck.ck-editor {
    border: 1px solid  #BED3E4 !important;
    position: relative;
    max-width: 525px;
    min-width: 525px;
    border-radius: 4px;
   
}

 .ck.ck-button{
  color: '#4E6287';
}

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
   
   
    background: #F8FAFC !important;
    border:none !important;
}

.ck.ck-dropdown {
  width: 150px;
  font-size: 14px;
}

.ck.ck-dropdown.ck-heading-dropdown .ck-dropdown__button .ck-button__label {
 
  font-family: 'NotoSans-Regular';
  color: #4E6287;
}

.ck.ck-icon.ck-icon_inherit-color {
  color: #4E6287;
}

.ck.ck-heading_heading1 {
  font-size: 18px;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-top: none;
  border-color: #BED3E4;
  font-family: 'NotoSans-Regular';
  font-size: 14px;
  color: #001F52;
}

.ck.ck-editor__editable{
  border: none !important;
  box-shadow: none !important;
  font-family: 'NotoSans-Regular'!important;
  font-size: 14px !important;
  color: #001F52 !important;
}



.ck.ck-balloon-panel{
  z-index:1400 !important;
}

.ck.ck-powered-by{
  display: none;
}

.ck.ck-reset_all {
  color: none !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all {

  font-family: 'NotoSans-Regular'!important;
  font-size: 14px !important;
 
}

.ck.ck-toolbar {
  border: none !important;
  background: none !important;
}

.ck-powered-by-balloon {
  display: none !important;
}
