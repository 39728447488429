@font-face {
    font-family: "NotoSans-Medium";   /*Can be any text*/
    src: local("NotoSans-Medium"),
      url("./fonts/Noto_Sans/NotoSans-Medium.woff") format("woff");
  }

@font-face {
  font-family: "NotoSans-SemiBold";
  /*Can be any text*/
  src: local("NotoSans-SemiBold"),
    url("./fonts/Noto_Sans/NotoSans-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "NotoSans-Regular";
  /*Can be any text*/
  src: local("NotoSans-Regular"),
    url("./fonts/Noto_Sans/NotoSans-Regular.woff") format("woff");
}